.verti-timeline {
    border-left: 3px dashed var(--bs-gray-300);
    margin: 0 10px;
}

.verti-timeline .event-list {
    position: relative;
    padding: 0 0 40px 30px;
}

.verti-timeline .event-list .event-timeline-dot {
    position: absolute;
    left: -9px;
    top: 0;
    z-index: 9;
    font-size: 16px;
}